import "./jquery";
import "select2";
import "select2/dist/css/select2.css";
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";
import "easymde/dist/easymde.min.css";

import "@nathanvda/cocoon";

import "bootstrap";
import EasyMDE from "easymde";
import AutoNumeric from "autonumeric";
import Rails from "@rails/ujs";

import "trix";
import "@rails/actiontext";
import Sortable from 'sortablejs';

Rails.start();

function setFollowUrl($box) {
  if ($box.hasClass("follow-url")) {
    $box.on("select2:select", e => {
      const url = e.params && e.params.data && e.params.data.url;
      if (url) {
        window.location.href = url;
      }
    });
  }
}

// Open link when clicking on table cell of row having 'data-link' attribute
$(function() {
  function isModifiedEvent(event) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
  }

  function clickEvent(event) {
    return new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
      metaKey: event.metaKey,
      altKey: event.altKey,
      ctrlKey: event.ctrlKey,
      shiftKey: event.shiftKey,
      button: event.button
    });
  }

  $(document.body).on("click", "tr[data-link] > td:not(.sortable-handle,:has(.btn,input))", function(event) {
    var to = $(this).parents("tr").data("link");

    if (!event.defaultPrevented && event.button === 0) {
      if (isModifiedEvent(event)) {
        var a = document.createElement("a");
        a.href = to;
        a.target = "_blank";
        a.dispatchEvent(clickEvent(event));
      } else {
        event.preventDefault();
        window.location = to;
      }
    }
  });
});

// Toggle checkbox when clicking on row with table cell having a checkbox in it
$(function() {
  $(document.body).on("click", "tr > td:has(input[type=checkbox]), tr > th:has(input[type=checkbox])", function(event) {
    const checkboxes = $(this).children("input[type=checkbox]");

    if(event.target.tagName !== "INPUT") {
      checkboxes.prop("checked", !checkboxes.prop("checked"));
      checkboxes.trigger("change");
    }
  });
});

// Toggle all checkboxes when clicking on 'select_all' checkbox
$(function() {
  $(document.body).on("change", "input[type=checkbox]#select_all", function(event) {
    const checkboxes = $(this).parents("table").find("tbody td input[type=checkbox]");
    checkboxes.prop("checked", $(event.target).prop("checked"));
  });
});

// Enable/disable mass update buttons on selecting checkboxes
$(function() {
  $(document.body).on("change", "input[type=checkbox]#select_all, tr > td > input[type=checkbox][name='option_ids[]']", function(event) {
    const options_selected = $("input[type=checkbox][name='option_ids[]']:checked").length > 0;
    $("button#archive_selected, button#delete_selected").prop("disabled", !options_selected);
  });
  // Trigger event on page load
  $("tr > td > input[type=checkbox][name='option_ids[]']:first").trigger("change");
});

$(function() {
  $("a[disabled=disabled]").on("click", function(e) {
    e.preventDefault();
    e.stopPropagation();
  });
});

$(function() {
  const $box = $(".select2");
  $box.select2({
    placeholder: $box.data("placeholder") || "",
    theme: "bootstrap4",
    allowClear: true,
    minimumResultsForSearch: $box.data("minimumresultsforsearch") || 0
  });
});

$(function() {
  const $box = $(".select2-input");
  $box.select2({
    placeholder: $box.data("placeholder") || "",
    theme: "bootstrap4",
    allowClear: true,
    tags: true
  });
});

$(function() {
  const $box = $(".select2-select-client");
  $box.select2({
    ajax: {
      url: "/clients",
      dataType: "json"
    },
    placeholder: $box.data("placeholder") || "",
    theme: "bootstrap4",
    allowClear: true
  });

  $box.on("select2:select", e => {
    const data = e.params.data;
    Object.keys(data).forEach(key => {
      const $field = $(`input.client_field[name="quotation[${key}]"]`);
      $field.val(data[key]);
    });
  });

  setFollowUrl($box);

  $box.on("change", e => {
    const current = $box.val();
    if (!current) {
      $("input.client_field").removeAttr("readonly");
    } else {
      $("input.client_field").attr("readonly", true);
    }
  });

  $box.trigger("change");
});

$(function() {
  const $box = $(".select2-select-machine");
  $box.select2({
    ajax: {
      url: "/machines",
      dataType: "json"
    },
    placeholder: $box.data("placeholder"),
    theme: "bootstrap4",
    allowClear: true
  });

  setFollowUrl($box);
});

$(function() {
  const options = $e => ({
    ajax: {
      url: "/options",
      dataType: "json"
    },
    placeholder: $e.data("placeholder"),
    theme: "bootstrap4",
    allowClear: false
  });

  const init = $e => {
    const $box = $e.select2(options($e));
    setFollowUrl($box);
  }

  init($(".select2-select-option:not(:disabled)"));

  $(document.body).on("cocoon:before-insert", (e, insertedItem) => {
    const $e = $(insertedItem).find(".select2-select-option");
    $e.select2(options($e));
  });
});

$(function() {
  const quotations = $e => ({
    ajax: {
      url: "/quotations",
      dataType: "json"
    },
    placeholder: $e.data("placeholder"),
    theme: "bootstrap4",
    allowClear: true
  });

  const init = $e => {
    const $box = $e.select2(quotations($e));
    setFollowUrl($box);
  }

  init($(".select2-select-quotation"));

  $(document.body).on("cocoon:before-insert", (e, insertedItem) => {
    const $e = $(insertedItem).find(".select2-select-quotation");
    $e.select2(quotations($e));
  });
});

$(function() {
  $(".mde").each((_index, e) => {
    const easymde = new EasyMDE({
      autoDownloadFontAwesome: false,
      element: e,
      spellChecker: false,
      status: false,
      toolbar: [{
        name: "bold",
        action: EasyMDE.toggleBold,
        className: "fas fa-bold",
        title: "Bold"
      }, {
        name: "italic",
        action: EasyMDE.toggleItalic,
        className: "fas fa-italic",
        title: "Italic"
      }, {
        name: "strikethrough",
        action: EasyMDE.toggleStrikethrough,
        className: "fas fa-strikethrough",
        title: "Strikethrough"
      }, "|", {
        name: "heading-1",
        action: EasyMDE.toggleHeading1,
        className: "fas fa-heading",
        title: "Header"
      }, {
        name: "heading-2",
        action: EasyMDE.toggleHeading2,
        className: "fas fa-heading fa-xs",
        title: "Small header"
      }, "|", {
        name: "ordered-list",
        action: EasyMDE.toggleOrderedList,
        className: "fas fa-list-ol",
        title: "Numbered list"
      }, {
        name: "unordered-list",
        action: EasyMDE.toggleUnorderedList,
        className: "fas fa-list-ul",
        title: "Bullet list"
      }, "|", {
        name: "horizontal-rule",
        action: EasyMDE.drawHorizontalRule,
        className: "fas fa-minus",
        title: "Line"
      }, "|", {
        name: "preview",
        action: EasyMDE.togglePreview,
        className: "fas fa-eye no-disable",
        title: "Preview"
      }]
    });

    $(e).data("easymde", easymde);
  });
});

/*
  * Hacky fix for a bug in select2 with jQuery 3.6.0's new nested-focus "protection"
  * see: https://github.com/select2/select2/issues/5993
  * see: https://github.com/jquery/jquery/issues/4382
  *
  * TODO: Recheck with the select2 GH issue and remove once this is fixed on their side
  */
$(document).on('select2:open', () => {
  document.querySelector('.select2-container--open .select2-search__field').focus();
});

$(function() {
  $(".custom-file-input").on("change",function(e){
    const fileName = this.files[0].name;
    $(this).next(".custom-file-label").addClass("selected").html(fileName);
  });
});

$(function() {
  window.currency_fields = AutoNumeric.multiple('.currency', {
    decimalCharacterAlternative: ",",
    digitGroupSeparator: ""
  });
});

$(function() {
  const total = parseFloat($("#total").val()) || 0;
  const $add = $("#add");
  const $substract = $("#substract");
  const $set = $("#set");
  const $amount = $("#amount");
  const $discount = $("#discount");
  const $percentage = $("#percentage");

  $percentage.on("input", () => {
    const factor = (parseFloat($percentage.val()) || 0) / 100;
    AutoNumeric.getAutoNumericElement($amount.get(0)).set(total * factor);
  });

  $add.on("click", () => {
    const current = parseFloat($discount.val()) || 0;
    const amount = parseFloat($amount.val() || 0);
    AutoNumeric.getAutoNumericElement($discount.get(0)).set(current + amount);
  });

  $substract.on("click", () => {
    const current = parseFloat($discount.val()) || 0;
    const amount = parseFloat($amount.val() || 0);
    AutoNumeric.getAutoNumericElement($discount.get(0)).set(current - amount);
  });

  $set.on("click", () => {
    const amount = parseFloat($amount.val() || 0);
    AutoNumeric.getAutoNumericElement($discount.get(0)).set(amount);
  });
});

$(function() {
  $(".btn#retrieve_exchange_rate").on("click", (e) => {
    const button = $(e.target);
    button.children(".far").removeClass("fa-file-import").addClass("fa-spinner fa-pulse");
    $("#ecb_yen_exchange_rate_error").text("");
    e.preventDefault();

    fetch("/quotations/retrieve_exchange_rate")
      .then(response => response.json())
      .then(data => {
        const jpy = data["rates"]["JPY"];

        $(".btn#apply_exchange_rate > span").text(" " + jpy + " JPY");
        $(".btn#apply_exchange_rate").data("exchangeRate", jpy);
        $(".btn#apply_exchange_rate").removeClass("invisible").addClass("updated");
        setTimeout(() => { $(".btn#apply_exchange_rate").removeClass("updated") }, 1500);

        button.children(".far").addClass("fa-file-import").removeClass("fa-spinner fa-pulse");
      })
      .catch(error => {
        console.error('Error:', error);
        button.children(".far").addClass("fa-file-import").removeClass("fa-spinner fa-pulse");
        $("#ecb_yen_exchange_rate_error").text("Could not retrieve latest exchange rate.");
      });
  });

  $(".btn#apply_exchange_rate").on("click", (e) => {
    const apply_button = $(".btn#apply_exchange_rate");
    e.preventDefault();

    for (const currency_field of window.currency_fields) {
      if(currency_field.domElement.id === "quotation_exchange_rate") {
        currency_field.set(apply_button.data("exchangeRate"));
        $(currency_field.domElement).addClass("updated");
        setTimeout(() => { $(currency_field.domElement).removeClass("updated") }, 1500);
      }
    }

    apply_button.tooltip('hide');
  });
});

$(function() {
  const $togglePurchasePrice = $("#toggle-purchase-price");
  const $purchasePrices = $(".purchase-price");

  if (localStorage.getItem('showPP') === null) {
    localStorage.setItem('showPP', 'true');
  }

  if (localStorage.getItem('showPP') === 'false') {
    $togglePurchasePrice.children("span").text('Show purchase price');
    $purchasePrices.hide();
  }

  $togglePurchasePrice.on("click", () => {
    const showPP = localStorage.getItem('showPP');
    if (showPP === 'true') {
      localStorage.setItem('showPP', 'false');
      $togglePurchasePrice.children("span").text('Show purchase price');
      $purchasePrices.hide();
    } else if (showPP === 'false') {
      localStorage.setItem('showPP', 'true');
      $togglePurchasePrice.children("span").text('Hide purchase price');
      $purchasePrices.show();
    }
  });
});

$(function() {
  const $optionCheckboxes = $(".option-check");
  $optionCheckboxes.on("click", (e) => {
    const checkBox = e.target;
    if (checkBox.checked) {
      $(checkBox).next("input").val(1);
    } else {
      $(checkBox).next("input").val(0);
    }
  });

  const $quantityCount = $(".amount")
  $quantityCount.on("change", (e) => {
    // Check checkbox when it's higher than 0
    const quantityField = e.target;
    const checkBox = $(quantityField).prev(".option-check");
    checkBox.prop("checked", quantityField.value !== '0');
  });
});

// Multiply prices using quantity on item edit form
$(function() {
  const flashFieldUpdated = (field) => {
    field.addClass("updated");
    setTimeout(() => { field.removeClass("updated") }, 1100);
  };

  $("form.item_form input#item_quantity, form.item_form input#item_purchase_price_single, form.item_form input#item_price_single").on("change", (e) => {
    const quantity_field = $("input#item_quantity");
    const quantity = parseFloat(quantity_field.val());
    const purchase_unit_price = parseFloat($("input#item_purchase_price_single").val());
    const purchase_price_field = $("input#item_purchase_price");
    const unit_price = parseFloat($("input#item_price_single").val());
    const price_field = $("input#item_price");

    if(isNaN(quantity))
      return;

    if(e.target.id === "item_quantity" || e.target.id === "item_purchase_price_single") {
      const purchase_price_element = AutoNumeric.getAutoNumericElement(purchase_price_field.get(0));
      purchase_price_element.clear();

      if(!isNaN(purchase_unit_price)) {
        purchase_price_element.set(quantity * purchase_unit_price);
      }
      flashFieldUpdated(purchase_price_field);
    }

    if(e.target.id === "item_quantity" || e.target.id === "item_price_single") {
      const unit_price_element = AutoNumeric.getAutoNumericElement(price_field.get(0));
      unit_price_element.clear();

      if(!isNaN(unit_price)) {
        unit_price_element.set(quantity * unit_price);
      }
      flashFieldUpdated(price_field);
    }
  });
});

// Sortable options using jquery-sortablejs and SortableJS
// See: https://github.com/SortableJS/jquery-sortablejs and https://sortablejs.github.io/Sortable
window.setupSortable = () => {
  const sortableElement = document.getElementById('sortable-options');

  const autoSubmitForm = () => {
    if(sortableElement.dataset.sortableAutosubmit === "true")
      sortableElement.closest("form").requestSubmit();
  };

  const updatePositionFields = () => {
    const positionFields = sortableElement.querySelectorAll(":not(.removed-option) > input[name$='[position]']");
    for (const [index, field] of positionFields.entries()) {
      field.value = index + 1;
    }
  };

  if(sortableElement !== null) {
    Sortable.create(sortableElement, {
      animation: 150,
      handle: ".sortable-handle",
      forceAutoScrollFallback: true,
      onUpdate: () => {
        updatePositionFields();
        autoSubmitForm();
      }
    });

    // Update position field on inserting/removing item in form
    $(sortableElement)
      .on('cocoon:after-insert', updatePositionFields)
      .on("cocoon:after-remove", function(e, removedItem, originalEvent) {
        removedItem.addClass("removed-option");
        updatePositionFields();
      });
  }
}
$(window.setupSortable);

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
})
